.website-settings-main-container {
    background-color: white;
    padding: 35px;
    border-radius: 24px;
  }
  .zero-margin {
    margin: 0px !important;
  }
  .scroll-y {
    overflow-y:scroll;
    max-height: 220px;
  }
  .caption {
    display: flex !important;    
  }
  .max-caption {
    background-color: #23B2D9 !important;
  }
  .min-caption {
    background-color: #23B2D9 !important;
  }
  .side-bar {
    background-color: #23B2D9 !important;
  }
  *{
      -ms-overflow-style: none;
  }
  .center {
    align-items: center !important;
    justify-content: center !important;
  }
.d-block {
  display: block !important;
  margin-left: 14%;
  margin-right: 10%;
}
.d-none {
  display: none !important;
}
.nav-item .nav-link.active {
  background-color: #ffffff !important;
  color: #23B2D9 !important;
}

.nav-item .nav-link.active .nav-icon { 
  color: #23B2D9 !important;
}
.summary-item { 
  font-size: large;
}

.summary-item-deep-font {
 font-weight: bold !important;
 text-decoration: underline;
 cursor: pointer;
}

.font-bold {
  font-weight: bolder !important;
  cursor: pointer;
}

.warehouse-search-col {
  margin-bottom: 24px !important;
  flex-direction: 'row' !important;
  display: 'flex' !important;
  align-items: "center" !important;
}

.supply-select-all { 
  font-size: medium;
  font-weight: bold !important;
  cursor: 'pointer' !important;
  padding-top: 4px !important;
  padding-left: 8px !important;
}

.supply-select-all:hover { 
  background-color: #23B2D9;
  color: white;
  cursor: 'pointer' !important;
}

.supply-select-item { 
  background-color: #23B2D9;
  color: white;
  cursor: 'pointer' !important;
}
.modal-header {
  background-color: #23B2D9;
  color: white;
}
.red-text {
  color: 'red' !important;
}
.max-width {
  width: -webkit-fill-available !important;
  width: -moz-available !important; /* Firefox */
}
.suggestions-list {
  position: absolute;
  width: 98%;
  /* padding-left: 28px !important; */
  /* padding-inline-start: 28px !important; */
  text-indent: 28px !important;
  background-color: #f5f5f5 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  z-index: 10;
  top: 100%; /* Moves the dropdown slightly below */
  transform: translateY(0px); /* Extra spacing below input */
}

.suggestions-list-item {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border: none !important;
  background-color: #f5f5f5 !important;
}

.suggestions-list-item:hover,
.suggestions-list-item.selected {
  background-color: #d6d6d6 !important;
  transition: background-color 0.2s ease-in-out;
}

/* Custom Input Field */
.custom-input {
  /* padding: 6px !important; */
  border-radius: 0px;
  border-width: 0px !important;
  background-color: #f5f5f5 !important;
  align-items: center !important;
}
.clear-button {
  background-color: #f5f5f5 !important;
  cursor: pointer;
  border: none; /* Removes default border if any */
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0px !important;
}

.search-bar-input {
  border: 1px solid #b1b7c1 !important;
  border-radius: .375rem !important;
  align-items: center !important;
  background-color: #f5f5f5 !important;
}
.custom-input:focus {
  box-shadow: none !important;
}
.custom-input-shadow-focus {
  box-shadow: 0.5px 0.5px 1px 1px #23B2D9;
}
.custom-input-shadow-blur {
  box-shadow: none !important;
}
.border-none {
  border: none !important;
}