/* Overriding the css classes of core ui  */

ul.pagination .page-item .page-link {
  color: #333333;
}

ul.pagination .active .page-link {
  background-color: #333333;
  border-color: #333333;
  color: #ffffff;
}

.website-settings-main-container {
  background-color: white;
  padding: 40px;
  border-radius: 24px;
  width: 100%;
}
.website-settings-mobile-container {
  background-color: white;
  padding: 0px;
  width: 100%;
  flex:auto;
}

.divider {
  border-left: 1px solid #D3D3D3;
}

.zero-margin {
  align-self: center;
  width: -webkit-fill-available;
}

.max-width {
  max-width: 100% !important;
}

.elv-btn {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
}
.elv-btn-outline{
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid white !important;
  color: white !important;
}
.elv-btn-header {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  color: #23B2D9 !important;
}

.elv-btn-white {
  background-color: 'transparent' !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
}

.elv-btn-white .accordion-button {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  box-shadow: var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
  height: 40px;
}

.elv-btn .accordion-button {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  box-shadow: var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
  height: 40px;
}

.btn-primary {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  box-shadow: var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-left-24 {
  padding-left: 24px;
}

.custom-list-group {
  display: inline !important;
}

.list-group-item.active {
  background-color: #23B2D9 !important;
  border: 1px solid #23B2D9 !important;
}

.no-decor {
  text-decoration: none;
  color: black;
}

.multi-range-slider .bar-inner {
  background-color: #23B2D9;
  box-shadow: none !important;
  border: none !important;
}

.multi-range-slider .bar-right {
  box-shadow: inset 0px 0px 2px black !important;
}

.multi-range-slider .bar-left {
  box-shadow: inset 0px 0px 2px black !important;
}

.selected-card {
  background-color: #23B2D9 !important;
  color: white !important;
}

.unselected-card {
  background-color: white !important;
}

.nav-tabs .nav-item .nav-link {
  color: black;
  background-color: white;
  border-bottom: 2px solid lightblue;
}

.nav-tabs .nav-item .active {
  color: #23B2D9 !important;
  background-color: white !important;
  border: none;
  border-bottom: 2px solid #23B2D9 !important;
}

.margin-left-12 {
  margin-left: 12px;
}
.padding-top-16 {
  padding-top: 16px;
}

.padding-top-24 {
  padding-top: 24px;
}

.margin-left-36 {
  margin-left: 36px;
}

.margin-left-44 {
  margin-left: 44px;
}

.flag-style {
  width: 24px !important;
  height: 24px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  margin-left: 12px !important;
  margin-right: 6 !important;
}
.margin-top-20 {
  margin-top: 20px;
}

.nav-tabs {
  border: none !important;
}

.card-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.customer-product-card-body {
  padding: 0 !important;
}

.customer-product-card-body-text {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.customer-sticky-header {
  padding: 0 !important;
  background-color: #23B2D9 !important;
}

.white-icon {
  color: #ffffff !important;
  height: 100% !important;
  border-radius: 8px;
}

.white-icon:hover {
  height: 100% !important;
  background-color: white !important;
  color: #23B2D9 !important;
}
.package-detail-button:hover {
  background-color: white !important;
  color: #23B2D9 !important;
}
.aspect-ratio-style {
  max-width: 100% !important;
  height: 415px !important;
  width: auto !important;
}

.aspect-ratio-l-style {
  /*For large image view*/
  max-width: 100% !important;
  height: 425px !important;
  width: auto !important;
}


.aspect-ratio-xl-style {
  /*For extra large image view*/
  max-width: 100% !important;
  height: 700px !important;
  width: auto !important;
}

.header-sticky {
  background-color: #23B2D9 !important;
  border-bottom: 0px !important;
}

.custom-margin-right {
  margin-right: 18rem;
}

.ribbon {
  width: 130px;
  height: 130px;
  overflow: hidden;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: rgb(67 163 211);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.not-allowed {
  cursor: not-allowed;
}

.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}

.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-45deg);
}

.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.centered-modal {
  position: fixed;
  top: 50%;
  left: 0% !important;
  
  transform: translate(-10%, 0%);
}

.checkbox {
  align-self: flex-end;
}

.white {
  color: white !important;
}

.url-decoration-sticky {
  cursor: pointer;
  text-decoration: none !important;
  border-bottom: 1px solid #23b2d9;
}

.url-decoration-non-sticky {
  cursor: pointer;
  text-decoration: none !important;
  border-bottom: 1px solid white;
}

.pdf-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.discount-badge {
  background-color: white;
  color: gray;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.discount-badge-white {
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-decoration-line-through {
  font-size: 15px;
  opacity: 0.8;
  margin: 0; /* Removes extra spacing */
}