/* Overriding the css classes of core ui  */

ul.pagination .page-item .page-link {
  color:#333333;
}

ul.pagination .active .page-link {
  background-color: #333333;
  border-color: #333333;
  color:#ffffff;
}

.website-settings-main-container {
  background-color: white;
  padding: 35px;
  border-radius: 24px;
  width: 100%;
 
}

.divider {
  border-left: 1px solid #D3D3D3;
}

.zero-margin {
  align-self: center;
  width: -webkit-fill-available;
}

.max-width {
  max-width: 100% !important;
}

.elv-btn {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
}
.elv-btn .accordion-button {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  box-shadow: var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
  height: 40px;
}

.elv-btn-ghost {
  background-color: 'transparent' !important;  
  color: #23B2D9 !important;
}
.elv-btn-header {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  color: #23B2D9 !important;
}
.elv-btn-ghost:hover {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
}

.elv-btn-ghost .accordion-button {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  box-shadow: var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
  height: 40px;
}

.btn-primary {
  background-color: var(--cui-form-check-input-checked-bg-color) !important;
  border: 1px solid var(--cui-form-check-input-checked-bg-color) !important;
  box-shadow: var(--cui-form-check-input-checked-bg-color) !important;
  color: white !important;
}

.padding-8 {
  padding: 8px !important;
}
.padding-top-16 {
  padding-top: 16px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.horizontal-padding-0 {

  padding-right: 0px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.custom-list-group {
  /* display: inline !important; */
  border-width: 2px solid black !important;
  border-radius: 0px !important;
}

.pagination-container {
  display: flex !important;
  justify-content: flex-start !important;
}

.list-group-item.active {
  background-color: #23B2D9 !important;
  border: 1px solid #23B2D9 !important;
}

.no-decor {
  text-decoration: none;
  color: black;
}

.multi-range-slider .bar-inner {
  background-color: #23B2D9;
  box-shadow: none !important;
  border: none !important;
}

.multi-range-slider .bar-right {
  box-shadow: inset 0px 0px 2px black !important; 
}

.multi-range-slider .bar-left {
  box-shadow: inset 0px 0px 2px black !important;
}

.selected-card {
  background-color: #23B2D9 !important;
  color: white !important;
  /* border-radius: 28px !important; */
  transition: transform 500ms ease;
}

.unselected-card {
  background-color: white !important;
  /* border-radius: 28px !important; */
  transition: transform 500ms ease;
}

.unselected-card:hover,
.unselected-card:focus-within {
  transform: scale(1.02);
}

.selected-card:hover,
.selected-card:focus-within {
  transform: scale(1.02);
}

.nav-tabs .nav-item .nav-link{
  color: black;
  background-color: white;
  border-bottom: 2px solid lightblue;
}

.spinner-grow.text-primary {
  background-color: #23B2D9;
}

.nav-tabs .nav-item .active {
  color: #23B2D9 !important;
  background-color: white !important;
  border: none;
  border-bottom: 2px solid #23B2D9 !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}


.margin-right-12 {
  margin-right: 12px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}


.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-36 {
  margin-top: 36px !important;
}

.margin-top-44 {
  margin-top: 44px !important;
}

.margin-top-54 {
  margin-top: 54px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-36 {
  margin-bottom: 36px !important;
}

.margin-bottom-44 {
  margin-bottom: 44px !important;
}

.nav-tabs {
  border: none !important;
}

.card-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.progress-bar {
  background-color: #23B2D9 !important;
}

.center {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.pointer {
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-container input { 
  /* Adjust other styles as needed */
  /* For example: */
  border: 1px solid #ccc;
  padding: 5px;
  width: 100% !important;  
  max-height: 25px !important;
  margin-right: 10px;
}

/* Optionally, style the icon */
.input-container CIcon {
  cursor: pointer;
  /* Add any other styles you need */
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center !important;
  width: 100%;
}

.product-card-info div {
  margin-top: 8px;
}

.aspect-ratio-style {
  max-width: '100%' !important;
  height: '500px' !important;
  width: 'auto' !important;
}

.datepicker {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px; 
}
.align-row-right {
  justify-content: end !important;
}
.align-col-right {
  display: flex;
  justify-content: right;
}
.red-text {
  color: 'red' !important; 
}
.url-decoration {
  text-decoration: underline !important;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.PhoneInputInput {
  height: 41px;
  border-radius: 8px;
  border: 1px solid grey;
}

.PhoneInputInput:focus{
  height: 41px;
  border-radius: 8px;
  
  border: 1px solid grey !important;
}
.show-password-text {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: black !important;
  font-size: medium !important;
}
.form-input-group {
  border: 1px solid #b1b7c1 !important;
  border-radius: .375rem !important;
  padding-right: .75rem !important;
  align-items: center !important;
  margin-bottom: 16px !important;
}

.show-hide-password-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.white {
  color: white;
}

.elv-blue {
  color: #23B2D9;
}

.all-sold-table {
  border-radius: 12px;
  border: 1px solid #23B2D9 !important;
  box-shadow: 1px 2px 9px #23B2D9;
}

.kpi-section {
  border-radius: 12px;
  border: 1px solid #23B2D9 !important;
  box-shadow: 1px 2px 9px #23B2D9;
}
.date-input {
  border: none !important;
  border-bottom: 2px solid #23B2D9 !important;
  cursor: pointer;
}

.date-input:focus {
  border: none !important;
  border-bottom: 2px solid #23B2D9 !important;
  cursor: pointer;
}
.smart-table-div-one {
  width: "100%";
  overflow: "auto";
  display: "flex";
  justify-content: "center";
  /* padding-top: 12px !important; */
}
.kpi-card {
  border: 1px solid black !important;
}
.border-none {
  border: 'none' !important;
}
.card-header {
  background-color: #23B2D9 !important;
  color: #ffffff !important;
}
span.opacity-25 {
  opacity: 1 !important;
}

.file-drop-area {
  border: 2px dashed #ccc !important;  /* Force Dashed Border */
  border-radius: 8px;  /* Optional: Smooth rounded corners */
  padding: 40px;
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column !important;
  transition: border-color 0.2s ease-in-out;
}

.file-drop-area:hover {
  border-color: #007bff; /* Change color on hover */
}

.file-drop-area h6 {
  margin-bottom: 20px; /* Adds spacing below the heading */
  display: block !important; 
  text-align: center;
}

.file-drop-area p {
  margin-top: 5px; /* Adds spacing above the paragraph */
}

@media (min-width: 768px) and (max-width: 1224px) {
  div .bg-danger {
      display: none !important;
  }
}